export function getShowRoomsList(state) {
  return state.showRoomsList
}
export function getSidebarLeft(state) {
  return state['sidebar-left']
}

export function getCheckShowListContact(state) {
  return state.checkShowListContact
}
export function getStyleHeight(state) {
  return state['style-height']
}
export function getStyleHeight2(state) {
  return state['style-height-2']
}

export function getBannerAlert(state) {
  return state['banner-alert']
}
export function getSidebarRight(state) {
  return state['sidebar-right']
}

export function getMessages(state) {
  return state['messages-filter']
}

export function getBannerAlertText(state) {
  return state['banner-alert-text']
}

export function getLabelFilter(state) {
  return state.labelFilter
}

export function getLabelValue(state) {
  return state.labelValue
}

export function getContactRooms(state) {
  return state.contactRooms
}

export function getEmptyLabel(state) {
  return state.isLabelEmpty
}

export function getNotifications(state) {
  return state.notifications
}

export function getCurrentOffset(state) {
  return state.currentOffset
}

export function getFilterRoom(state) {
  return state.filterRoom
}

export function getNewChat(state) {
  return state.newChat
}

export function getNewChatContent(state) {
  return state.newChatContent
}

export function getFilterChats(state) {
  return state.filterChats
}

export function getShowCheckbox(state) {
  return state.showCheckbox
}

export function getIsTagChanged(state) {
  return state.isTagChanged
}

export function getLoadingDownloadFile(state, data) {
  return state.loadingDownloadFile
}
