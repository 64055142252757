import firestoreDbMixin from './firestoreDbMixin'
import inboxMixin from './inboxMixin'
import pouchDbMixin from './pouchDbMixin'

export default {
  mixins: [pouchDbMixin, firestoreDbMixin, inboxMixin],
  methods: {
    async loadContactsIdList(idList) {
      // Ambil data remote jika di contact list
      const remote = idList !== undefined
      const items = await this.loadContacts(remote)

      const obj = {
        contacts: [],
        totalContact: 0,
      }

      items.forEach(x => {
        // map phone number show group
        if (x.phone_number_show) {
          if (x.phone_number_show.includes('-') || x.phone_number_show.length > 16) {
            x.phone_number_show = 'Group'
          }
        }

        if (x._id !== x.phone_number) {
          obj.totalContact += 1
        }

        // if (idList) {
        //   if (x._id.startsWith(idList)) {
        //     obj.contacts.push(x)
        //   }
        // } else if (x._id === x.phone_number) {
        //   obj.contacts.push(x)
        // }
        if (x._id === `${x.instance_id}-${x.phone_number}`) {
          obj.contacts.push(x)
        }
      })

      return obj
    },
    async editPinned(contact) {
      if (!contact.pinned) {
        contact.pinned = 1
      } else {
        contact.pinned = 0
      }
      await this.updateRoomCouchDb(contact.subId, contact.instance_id, contact.roomId, { pinned: contact.pinned })

      // await this.fdbUpdateChatData(contact)

      // await this.changeFavouriteContact(contact, async success => {
      //   if (success) {
      //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
      //       await this.loadTable()
      //     })
      //   }
      // })
    },

    // async setOperator(operator, contact) {
    //   contact.assign_to = operator

    //   await this.updateRoomCouchDb(contact.subId, contact.instance_id, contact.roomId, { assign_to: contact.assign_to })

    //   // await this.fdbUpdateChatData(contact)

    //   // await this.changeFavouriteContact(contact, async success => {
    //   //   if (success) {
    //   //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
    //   //       await this.loadTable()
    //   //     })
    //   //   }
    //   // })
    // },
    async loadContactsAll() {
      const items = await this.loadContacts()

      const obj = {
        contacts: items,
        totalContact: items.length,
      }

      return obj
    },
    async setOperator(operator, customer) {
      customer.assign_to = operator
      await this.updateRoomCouchDb(customer.subId, customer.instance_id, customer.roomId, {
        assign_to: customer.assign_to,
      })

      // this.contactDetail = customer

      // await this.updateContact(customer)
    },
    async changeFavouriteContact(contact, callback = false) {
      if (contact.favourite) {
        contact.favourite = false
      } else {
        contact.favourite = true
      }

      this.contactDetail = contact

      await this.updateContact(contact, callback)
    },
    async addContactToList(data, idlist, contactList) {
      const curentList = contactList.find(x => x.list_id === idlist.list_id)
      const currentContact = curentList ? curentList.contacts : []
      const bulkInsert = []
      const bulkUpdate = []

      const colSettingList = idlist.columns
      const columnAdditional = colSettingList
        ? colSettingList.filter(x => x.deleteable).sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
        : []

      data.forEach(item => {
        const isPhoneNumberExists = currentContact.some(contact => contact.phone_number === item.phone_number)
        if (!isPhoneNumberExists) {
          //   const newItem = currentContact.find(x => x.phone_number === item.phone_number)
          //   for (const field in item) {
          //     newItem[field] = item[field]
          //   }

          //   columnAdditional.forEach(dataColumn => {
          //     if (!newItem.hasOwnProperty(dataColumn.field) && dataColumn.defaultValue && dataColumn.field !== 'label') {
          //       newItem[dataColumn.field] = dataColumn.defaultValue
          //     }
          //   })
          // } else {
          const idx = bulkInsert.findIndex(x => x.phone_number === item.phone_number)
          if (idx > -1) {
            bulkInsert.splice(idx, 1)
          }
          const newItem = {}
          for (const field in item) {
            newItem[field] = item[field]
          }
          bulkInsert.push(newItem)
        }
      })

      // const finalBulk = bulkInsert.concat(bulkUpdate)
      bulkInsert.forEach(dataContact => {
        columnAdditional.forEach(dataColumn => {
          if (
            !dataContact.hasOwnProperty(dataColumn.field) &&
            dataColumn.defaultValue &&
            dataColumn.field !== 'label'
          ) {
            dataContact[dataColumn.field] = dataColumn.defaultValue
          }
        })
      })

      curentList.contacts = curentList.contacts.concat(bulkInsert)

      await this.$store.dispatch('global/insertOrUpdateContactList', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        ...curentList,
      })
    },
    async importApi(data, contactList, idlist, columnsAdditional, callback = false) {
      const bulkInsert = []
      const bulkUpdate = []
      data.map(item => {
        const isPhoneNumberExists = contactList.some(contact => contact.phone_number === item.phone_number)
        if (!item._id) {
          item._id = idlist ? `${idlist}${item.phone_number}` : item.phone_number
        }

        if (isPhoneNumberExists) {
          const newItem = contactList.find(x => x._id === item._id)
          for (const field in item) {
            newItem[field] = item[field]
          }
          bulkUpdate.push(newItem)
        } else {
          const idx = bulkInsert.findIndex(x => x.phone_number === item.phone_number)
          if (idx > -1) {
            bulkInsert.splice(idx, 1)
          }

          bulkInsert.push(item)
        }
      })
      const finalBulk = bulkInsert.concat(bulkUpdate)
      finalBulk.map(dataContact => {
        delete dataContact._uid
        //console.log('checkIdx', dataContact)

        columnsAdditional.forEach(dataColumn => {
          if (
            !dataContact.hasOwnProperty(dataColumn.field) &&
            dataColumn.defaultValue &&
            dataColumn.field !== 'label'
          ) {
            dataContact[dataColumn.field] = dataColumn.defaultValue
          }
        })
      })

      await this.bulkUpdateContact(finalBulk, callback)
    },
    async mappingContact(model, isEdit, curdataContact) {
      if (!model.contactName) {
        return {
          error: true,
          errorMessage: this.$t('contacts.contactsNameRequired'),
          data: {},
        }
      }
      if (!model.phoneNumber) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberRequired'),
          data: {},
        }
      }
      if (!model.nickname) {
        return {
          error: true,
          errorMessage: this.$t('contacts.nicknameRequired'),
          data: {},
        }
      }
      if (!isEdit) {
        model.phoneNumber = model.phoneNumber.replace(/[a-zA-Z]/g, '')
        model.phoneNumber = model.phoneNumber.replace(/\D/g, '')

        if (model.phoneNumber.startsWith('0')) {
          model.phoneNumber = model.phoneNumber.replace('0', '62')
        }
      }
      if (
        !/\d{12,13}/.test(model.phoneNumber) &&
        !/\d{10,11}/.test(model.phoneNumber) &&
        !/\d{15}/.test(model.phoneNumber)
      ) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberNotValid'),
          data: {},
        }
      }
      const isExist = curdataContact.find(x => x.phone_number === model.phoneNumber)
      if (isExist && !isEdit) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberExist'),
          data: {},
        }
      }
      let data = {}
      if (!isEdit) {
        data = {
          name: model.contactName,
          nickname: model.nickname,
          type: model.type,
          _id: model.phoneNumber,
          phone_number: model.phoneNumber,
          phone_number_show: model.phoneNumber && model.phoneNumber.includes('-') ? 'Group' : model.phoneNumber,
        }
      } else {
        data = model
        data.name = model.contactName
        data.updated_at = this.$moment().valueOf()
      }
      if (!data._uid) {
        data._uid = this.$nanoid()
      }
      if (!isEdit) {
        return {
          error: false,
          errorMessage: '',
          data: curdataContact.concat([data]),
        }
      }

      return {
        error: false,
        errorMessage: '',
        data: curdataContact,
      }
    },

    async mappingContactList(model, columnsAdditional, isEdit, curdataContact, couchAllContact = []) {
      // //console.log('masuk ke Kontak yeyyyyyyyy', model, columnsAdditional, idlist, isEdit, curdataContact)
      if (!model.contactName) {
        return {
          error: true,
          errorMessage: this.$t('contacts.contactsNameRequired'),
          data: {},
        }
      }
      // if (!model.nickname) {
      //   return {
      //     error: true,
      //     errorMessage: this.$t('contacts.nicknameRequired'),
      //     data: {},
      //   }
      // }
      if (!model.phoneNumber) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberRequired'),
          data: {},
        }
      }
      let error = false
      let errorFieldRequired = ''
      columnsAdditional.forEach(x => {
        const obj = model.data.find(obj => obj.attributeSelected === x.text)
        if (obj) {
          if (x.isRequired && !obj.attributeValue) {
            errorFieldRequired = x.text
            error = true
          }
        }
      })
      if (error) {
        return {
          error: true,
          errorMessage: `${errorFieldRequired} is Required`,
          data: {},
        }
      }
      if (!isEdit) {
        model.phoneNumber = model.phoneNumber.replace(/[a-zA-Z]/g, '')
        model.phoneNumber = model.phoneNumber.replace(/\D/g, '')

        if (model.phoneNumber.startsWith('0')) {
          model.phoneNumber = model.phoneNumber.replace('0', '62')
        }
      }
      if (
        !/\d{12,13}/.test(model.phoneNumber) &&
        !/\d{10,11}/.test(model.phoneNumber) &&
        !/\d{15}/.test(model.phoneNumber)
      ) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberNotValid'),
          data: {},
        }
      }
      const isExist = curdataContact.find(x => x.phone_number === model.phoneNumber)
      if (isExist && !isEdit) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberExist'),
          data: {},
        }
      }
      let data = {}
      if (!isEdit) {
        data = {
          name: model.contactName,
          nickname: model.nickname ? model.nickname : model.contactName,
          _id: model.phoneNumber,
          phone_number: model.phoneNumber,
          phone_number_show: model.phoneNumber && model.phoneNumber.includes('-') ? 'Group' : model.phoneNumber,
          created_by: this.user.email,
          updated_by: this.user.email,
          created_at: Date.now(),
        }
        columnsAdditional.forEach(x => {
          const obj = model.data.find(obj => obj.attributeSelected === x.text)
          if (obj) {
            data[x.name] = obj.attributeValue
          }
        })
      } else {
        data = model
        data.name = model.contactName
        data.nickname = model.nickname
        ;(data.updated_by = this.user.email),
          // data.updated_at = this.$moment().valueOf()
          columnsAdditional.forEach(x => {
            const obj = model.data.find(obj => obj.attributeSelected === x.text)
            if (obj) {
              data[x.name] = obj.attributeValue
            }
          })
      }
      if (!data._uid) {
        data._uid = this.$nanoid()
      }

      if (model.phoneNumber.startsWith('0')) {
        model.phoneNumber = model.phoneNumber.replace('0', '62')
      }

      const foundData = couchAllContact.find(x => x.phone_number === model.phoneNumber)
      if (foundData) {
        model.has_wa = 'Yes'
        model.type = 'Whatsapp'
      } else {
        model.type = 'Phone number'
      }
      data.has_wa = model.has_wa
      data.type = model.type
      data.updated_at = Date.now()

      if (!isEdit) {
        return {
          error: false,
          errorMessage: '',
          data: curdataContact.concat([data]),
        }
      }

      return {
        error: false,
        errorMessage: '',
        data: curdataContact,
      }
    },

    async insertToDefaultContactFirestore(data) {
      this.loading = true
      const res = await this.$store.dispatch('firestore/updateContacts', {
        list_id: `default`,
        sub_id: this.user.sub_id,
        contacts: data,
      })
      if (res) {
        setTimeout(async () => {
          await this.fdbGetAllContact(this.user.sub_id)
        }, 1000)
      }
      this.loading = false
    },

    async insertToListFirestore(data, idList) {
      this.loading = true
      const res = await this.$store.dispatch('firestore/updateContacts', {
        list_id: idList,
        sub_id: this.user.sub_id,
        contacts: data,
      })
      if (res) {
        setTimeout(async () => {
          await this.fdbGetAllContact(this.user.sub_id)
        }, 1000)
      }
      this.loading = false
    },

    async insertToFirestore(data) {
      this.loading = true
      const res = await this.$store.dispatch('firestore/updateContacts', {
        list_id: this.idlist,
        sub_id: this.user.sub_id,
        contacts: data,
      })
      if (res) {
        setTimeout(async () => {
          await this.fdbGetContactListContent(this.user.sub_id, this.idlist)
        }, 1000)
      }
      this.loading = false
    },

    async saveContactToDb(model, columnsAdditional, idlist, isEdit, curdataContact) {
      // //console.log('masuk ke Kontak yeyyyyyyyy', model, columnsAdditional, idlist, isEdit, curdataContact)
      if (!model.contactName) {
        return {
          error: true,
          errorMessage: this.$t('contacts.contactsNameRequired'),
          data: {},
        }
      }
      if (!model.phoneNumber) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberRequired'),
          data: {},
        }
      }
      let error = false
      let errorFieldRequired = ''
      columnsAdditional.forEach(x => {
        const obj = model.data.find(obj => obj.attributeSelected === x.text)
        if (obj) {
          if (x.isRequired && !obj.attributeValue) {
            errorFieldRequired = x.text
            error = true
          }
        }
      })
      if (error) {
        return {
          error: true,
          errorMessage: `${errorFieldRequired} is Required`,
          data: {},
        }
      }
      if (!isEdit) {
        model.phoneNumber = model.phoneNumber.replace(/[a-zA-Z]/g, '')
        model.phoneNumber = model.phoneNumber.replace(/\D/g, '')

        if (model.phoneNumber.startsWith('0')) {
          model.phoneNumber = model.phoneNumber.replace('0', '62')
        }
      }
      if (!/\d{12,13}/.test(model.phoneNumber)) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberNotValid'),
          data: {},
        }
      }
      const isExist = curdataContact.find(x => x.phone_number === model.phoneNumber)
      if (isExist && !isEdit) {
        return {
          error: true,
          errorMessage: this.$t('contacts.phoneNumberExist'),
          data: {},
        }
      }
      let data = {}
      if (!isEdit) {
        data = {
          _id: idlist ? `${idlist}${model.phoneNumber}` : model.phoneNumber,
          notes: model.notes,
          id: model.id,
          source: model.source,
          created_at: model.created_at,
          updated_at: model.created_at,
          name: model.contactName,
          phone_number: model.phoneNumber,
          phone_number_show: model.phoneNumber && model.phoneNumber.includes('-') ? 'Group' : model.phoneNumber,
          status: model.status ? model.status : '',
          last_reply: model.last_reply ? model.last_reply : '',
          last_message: model.last_message ? model.last_message : '',
          selected: model.selected ? model.selected : '',
          profile_picture: model.profile_picture ? model.profile_picture : '',
        }
        columnsAdditional.forEach(x => {
          const obj = model.data.find(obj => obj.attributeSelected === x.text)
          if (obj) {
            data[x.name] = obj.attributeValue
          }
        })
      } else {
        //console.log('yokkkkkkkkkk')
        data = model
        data.name = model.contactName
        data.updated_at = this.$moment().valueOf()
        columnsAdditional.forEach(x => {
          const obj = model.data.find(obj => obj.attributeSelected === x.text)
          if (obj) {
            data[x.name] = obj.attributeValue
          }
        })
      }
      const failed = false
      const errorMessage = ''
      await this.updateRoomCouchDb(this.user.subId, data.instance_id, data.roomId, { name: data.name })

      // await this.updateContact(data, async status => {
      //   if (status === false) {
      //     failed = true
      //     errorMessage = this.$t('warningfailedsavedata')
      //   }
      // })

      return {
        error: failed,
        errorMessage,
        data,
      }
    },
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null
    },
    async saveConfigToDb(listDraggable, tableColumns, hasEform) {
      listDraggable.forEach((data, index) => {
        data.order_number = index
      })
      const configTemp = listDraggable
      const notValid = configTemp.find(x => this.isEmptyOrSpaces(x.text))
      if (notValid) {
        return {
          error: true,
          errorMessage: this.$t('contacts.columnNameRequired'),
          data: {},
        }
      }
      configTemp.forEach(x => {
        if (x.id === undefined) {
          x.id = this.$nanoid()
          if (x.deleteable) {
            x.name = x.text.toLowerCase().replaceAll(' ', '_')
            x.field = x.text.toLowerCase().replaceAll(' ', '_')
            x.value = x.text.toLowerCase().replaceAll(' ', '_')
            x.label = x.text
          }
        } else if (x.deleteable) {
          x.label = x.text
        }
      })
      const finalConfig = []
      tableColumns.forEach(col => {
        const column = configTemp.find(x => x.id === col.id)
        if (column !== undefined) {
          finalConfig.push(column)
        } else if (!col.deleteable) {
          finalConfig.push(col)
        }
      })
      configTemp.forEach(col => {
        const column = tableColumns.find(x => x.id === col.id)
        if (column === undefined) {
          finalConfig.push(col)
        }
      })
      if (hasEform) {
        finalConfig.push({
          id: this.currentList.columns.length + 1,
          name: 'answerData',
          align: 'left',
          label: 'Answer Data',
          field: 'answerData',
          value: 'answerData',
          text: 'Answer Data',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: true,
          order_number: this.currentList.columns.length + 1,
        })
      }

      this.currentList.columns = finalConfig
      const obj = {
        sub_id: this.user.sub_id,
        token: this.user.token,
        ...this.currentList,
      }
      const res = await this.$store.dispatch('global/insertOrUpdateContactList', obj)
      if (!res.status) {
        return {
          error: true,
          errorMessage: this.$t('warningfailedsavedata'),
          data: finalConfig,
        }
      }

      // await this.saveAttribute(finalConfig, success => {
      //   if (!success) {
      //     return {
      //       error: true,
      //       errorMessage: this.$t('warningfailedsavedata'),
      //       data: finalConfig,
      //     }
      //   }
      // })

      return {
        error: false,
        errorMessage: '',
        data: finalConfig,
      }
    },
    deleteUnusedContact(contactListTable, listContactValue) {
      // const deleteContacts = contactListTable.filter(x => {
      //   if (x._id !== x.phone_number) {
      //     return !listContactValue.find(y => y.id === x._id.split(x.phone_number)[0])
      //   }
      //   return false
      // })
      // if (deleteContacts.length > 0) {
      //   deleteContacts.forEach(data => {
      //     data._deleted = true
      //   })
      //   this.bulkUpdateContact(deleteContacts)
      // }
    },
    async getAdditionalColumns(listContactValue, idlist) {
      const currentList = listContactValue.find(x => x.id === idlist)
      const items = await this.loadSettings()

      const colSettingList = currentList.deleteable
        ? items.find(x => x._id === `${idlist}columns`)
        : items.find(x => x._id === 'columns')
      const columnAdditional = colSettingList
        ? colSettingList.value.filter(x => x.deleteable).sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
        : []

      return columnAdditional
    },
  },
}
