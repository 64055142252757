export default function () {
  return {
    showRoomsList: true,

    //  room
    'sidebar-left': null,
    checkShowListContact: null,
    'style-height': null,
    'style-height-2': null,
    'banner-alert': null,
    'sidebar-right': null,
    'messages-filter': null,
    'banner-alert-text': '',

    // label
    labelFilter: null,
    labelValue: [],
    contactRooms: [],
    isLabelEmpty: false,
    currentOffset: 0,
    filterRoom: 'all',
    notifications: [],
    newChat: false,
    newChatContent: null,
    filterChats: null,
    showCheckbox: false,
    isTagChanged: false,
    loadingDownloadFile: false,
  }
}
