<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
import Vue from 'vue'
// eslint-disable-next-line object-curly-newline
import { App as CapacitorApp } from '@capacitor/app'
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import useDynamicVh from '@core/utils/useDynamicVh'

//  Layout inbox
import LayoutContentHorizontalNavInbox from '@/layouts/variants/contentInbox/horizontal-nav/LayoutContentHorizontalNavInbox.vue'
import LayoutContentVerticalNavInbox from '@/layouts/variants/contentInbox/vertical-nav/LayoutContentVerticalNavInbox.vue'

// Dynamic vh
Vue.mixin({
  data() {
    return {
      allContactsGlobal: [],
      allRoomsGlobal: [],
    }
  },
})

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutContentVerticalNavInbox,
    LayoutContentHorizontalNavInbox,
  },
  created() {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp()
      } else {
        window.history.back()
      }
    })
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    // Layout
    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`
      if (route.value.meta.layout === 'contentInbox') return `layout-content-${appContentLayoutNav.value}-nav-inbox`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
<style>
/* .v-dialog--active {
  overflow-x: hidden !important;
} */

.vac-col-messages .vac-container-scroll {
  /* background: var(--chat-content-bg-color); */
  flex: 1;
  overflow-y: auto;
  overflow-anchor: none !important;
  margin-right: 1px;
  margin-top: 60px;
  -webkit-overflow-scrolling: touch;
}

.vac-col-messages .vac-container-scroll::-webkit-scrollbar {
  width: 2px;
}

.vac-col-messages .vac-container-scroll::-webkit-scrollbar-track {
  background: #757779;
}
.vac-col-messages .vac-container-scroll::-webkit-scrollbar-thumb {
  background: #8bd2d6;
}
.vac-col-messages .vac-container-scroll::-webkit-scrollbar-thumb:hover {
  background: #90e3dc;
}

.vac-rooms-container .vac-room-list {
  flex: 1;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  padding: 0 10px 5px;
  overflow-y: scroll;
}

/* width */
.vac-rooms-container .vac-room-list::-webkit-scrollbar {
  width: 2px;
  border-radius: 15px;
  transition: background 0.5s ease-in-out; /* Add a smooth transition effect */
}

/* Track */
.vac-rooms-container .vac-room-list::-webkit-scrollbar-track {
  background: #aeaeae;
  border-radius: 15px;
  transition: background 0.5s ease-in-out; /* Add a smooth transition effect */
}

/* Handle */
.vac-rooms-container .vac-room-list::-webkit-scrollbar-thumb {
  background: #8bd2d6;
  transition: background-color 0.5s ease-in-out;
  border-radius: 15px;
}

/* Handle on hover */
.vac-rooms-container .vac-room-list:hover::-webkit-scrollbar-thumb {
  background: #90e3dc;
  width: 7px;
  transition: background 0.5s ease-in-out; /* Add a smooth transition effect */
}

.vac-rooms-container .vac-room-list:hover::-webkit-scrollbar {
  width: 7px;
  border-radius: 15px;
  transition: background 0.5s ease-in-out; /* Add a smooth transition effect */
}

/* .v-data-table-header__icon {
  opacity: 1;
} */

/* .vac-rooms-container .vac-room-list::-webkit-scrollbar {
  display: none;
} */
</style>
