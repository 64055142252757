/* eslint-disable */
import { firestoreDb } from '@demo/database/index'
import { collectionGroup, doc, getDocs, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore'
import _findIndex from 'lodash/findIndex'
import _orderBy from 'lodash/orderBy'
import cache from 'memory-cache'
import PouchDB from 'pouchdb-browser'
import PouchDBFind from 'pouchdb-find'
import TransformPouch from 'transform-pouch'
import firestoreDbMixin from './firestoreDbMixin'
import inboxMixin from './inboxMixin'

PouchDB.adapter('worker', require('worker-pouch'))
PouchDB.plugin(PouchDBFind)
PouchDB.plugin(TransformPouch)

export default {
  mixins: [inboxMixin, firestoreDbMixin],
  data() {
    return {
      isActive: true,
      pouchChats: null,
      chatWatcher: null,

      pouchMessages: null,
      pouchMessagesTokopedia: null,
      pouchContactsList: null,
      pouchContacts: null,
      pouchRoomsTokopedia: null,
      contactWatcher: null,

      pouchSettings: null,
      settingWatcher: null,

      pouchLink: null,
      pouchEordering: null,
      linkWatcher: null,

      pouchBroadcast: null,
      broadcastWatcher: null,

      pouchHistory: null,
      historyWatcher: null,

      tokopediaRoomWatcher: null,
      tokopediaChatWatcher: null,

      remoteContactsCount: null,
      remoteChatsCount: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    serverConnectionStatus() {
      return this.$store.getters['global/getServerConnectionStatus']
    },
  },
  async beforeDestroy() {
    if (this.broadcastWatcher !== null) this.broadcastWatcher.cancel()
    if (this.contactWatcher !== null) this.contactWatcher.cancel()
    if (this.settingWatcher !== null) this.settingWatcher.cancel()
    if (this.historyWatcher !== null) this.historyWatcher.cancel()
    if (this.linkWatcher !== null) this.linkWatcher.cancel()
    if (this.tokopediaChatWatcher !== null) this.tokopediaChatWatcher.cancel()
    if (this.tokopediaRoomWatcher !== null) this.tokopediaRoomWatcher.cancel()
  },
  methods: {
    async connectPouchDb(name, user = false, isReplicate = true) {
      const self = this
      let conn = await this.checkStatusConnectionServer()

      if (!user) {
        user = self.user
      }

      const local = `db_${name}`

      const tmp = cache.get(local)

      if (tmp) {
        return tmp
      }

      const dbName = `${user.sub_id}_${name}`
      const host = `${process.env.VUE_APP_COUCH_DB_URL}${dbName}`
      // const db = new PouchDB(local, { auto_compaction: true })
      const db = new PouchDB(local)

      // replicate from
      let replication = null
      let remote = null
      if (conn) {
        remote = new PouchDB(host, {
          fetch(url, opts) {
            opts.headers.set('Authorization', `Bearer ${user.token}`)
            opts.headers.set('Content-Type', 'application/json')

            return PouchDB.fetch(url, opts)
          },
        })
        // Add checking if replicate was running, it didn't run again
        // let statusReplication = this.$store.getters[`global/getReplicateStatus`]

        // if (isReplicate && statusReplication[name] !== 'ongoing') {
        //   const self = this
        //   replication = db.replicate
        //     .from(remote, {
        //       live: false,
        //       retry: false,
        //       batch_size: 500,
        //       batches_limit: 5,
        //     })
        //     .on('active', async info => {
        //       // update status replication to vuex
        //       let obj = {}
        //       obj[name] = 'ongoing'
        //       self.$store.dispatch('global/updateReplicateStatus', obj)
        //     })
        //     .on('paused', function (info) {
        //       // update status replication to vuex
        //       let obj = {}
        //       obj[name] = 'idle'
        //       self.$store.dispatch('global/updateReplicateStatus', obj)
        //     })
        //     .on('complete', async info => {
        //       //console.log('Replication From complete - ' + name)
        //       // update status replication to vuex
        //       let obj = {},
        //         obj2 = {}
        //       obj[name] = 'complete'
        //       obj2[name] = true
        //       self.$store.dispatch('global/updateReplicateStatus', obj)
        //       self.$store.dispatch('global/setInitReplicateStatus', obj2)
        //     })
        //     .on('error', err => {
        //       //console.log('Replication error:', err)
        //       // update status replication to vuex
        //       let obj = {}
        //       obj[name] = 'idle'
        //       self.$store.dispatch('global/updateReplicateStatus', obj)
        //     })
        // }
      }

      // transform contacts at inbox
      // const route = this.$route

      // if (name == 'contacts' && route.name == 'inbox') {
      //   remote.transform({
      //     incoming: function (doc) {
      //       // do something to the document before storage
      //       return doc
      //     },
      //     outgoing: async function (doc) {
      //       // do something to the document after retrieval
      //       doc = await self.getInboxTransform(doc)
      //       return doc
      //     },
      //   })
      // }

      const obj = {
        db,
        remote,
        dbName,
        replication,
      }

      cache.put(local, obj)

      return obj
    },
    async replicateToPouchDb(db, remote) {
      await db.replicate
        .to(remote, {
          retry: true,
        })
        .on('complete', async info => {
          //console.log('Replication To complete')
        })
        .on('error', err => {
          //console.log('Replication error:', err)
        })
    },
    async replicateRedirect(name, user = false, callback = false) {
      const pouch = await this.connectPouchDb(name, user)
      await pouch.db.replicate
        .from(pouch.remote, {
          retry: false,
        })
        .on('complete', async info => {
          if (typeof callback === 'function') {
            callback(true)
          }

          //console.log(`Replication From ${name} complete`)
        })
        .on('error', err => {
          if (typeof callback === 'function') {
            callback(false)
          }
          //console.log('Replication error:', err)
        })
    },
    async replicateFromPouchDbGlobal(name, user = false, callback = false) {
      const pouch = await this.connectPouchDb(name, user, false)

      // update status replication to vuex
      let obj = {}
      obj[name] = 'ongoing'
      this.$store.dispatch('global/updateReplicateStatus', obj)
      const self = this
      await pouch.db.replicate
        .from(pouch.remote, {
          live: false,
          retry: true,
          batch_size: 500,
          batches_limit: 5,
        })
        .on('active', async info => {
          // update status replication to vuex
          let obj = {}
          obj[name] = 'ongoing'
          self.$store.dispatch('global/updateReplicateStatus', obj)
        })
        .on('paused', function (info) {
          // update status replication to vuex
          let obj = {}
          obj[name] = 'idle'
          self.$store.dispatch('global/updateReplicateStatus', obj)
        })
        .on('complete', async info => {
          // update status replication to vuex
          let obj = {},
            obj2 = {}
          obj[name] = 'complete'
          obj2[name] = true
          self.$store.dispatch('global/updateReplicateStatus', obj)
          self.$store.dispatch('global/setInitReplicateStatus', obj2)

          if (typeof callback === 'function') {
            callback(info)
          }
          //console.log('Replication From global complete - ', name)
        })
        .on('error', err => {
          //console.log('Replication error:', err)

          // update status replication to vuex
          let obj = {}
          obj[name] = 'idle'
          self.$store.dispatch('global/updateReplicateStatus', obj)
        })
    },
    changesPouchDb(db, callback = false) {
      // //console.log(`listen on ${db.name}`)

      return db
        .changes({
          since: 'now',
          live: true,
          include_docs: true,
        })
        .on('change', async change => {
          // //console.log(`on change on ${db.name}`, change)

          if (typeof callback === 'function') {
            callback(change)
          }
        })
        .on('error', err => {
          console.log(err)
        })
    },
    onChangePouchDb(db, eventName = '') {
      // //console.log(`listen on ${db.name}`)

      return db
        .changes({
          since: 'now',
          live: true,
          include_docs: true,
        })
        .on('change', async change => {
          if (eventName) {
            this.$eventBus.$emit(eventName, change)
            if (eventName === process.env.VUE_APP_EVENT_UPDATE_CONTACTS) {
              this.$eventBus.$emit(`${process.env.VUE_APP_EVENT_UPDATE_CONTACTS}INBOX`, change)
            }
            if (!this.serverConnectionStatus) {
              await this.checkStatusConnectionServer()
            }
          }
        })
        .on('error', async err => {
          // this.$router.push({ name: 'channels' }, () => {})
          console.log(err)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          await this.checkStatusConnectionServer()
        })
    },
    async bulkUpdate(data, pouch, callback = false) {
      await pouch.remote
        .bulkDocs(data)
        .then(async x => {
          if (typeof callback === 'function') {
            callback(x)
          }
        })
        .catch(err => {
          if (typeof callback === 'function') {
            callback(false)
          }
          console.error(err)
        })
    },
    async update(data, pouch, callback = false) {
      if (data.hasOwnProperty('roomId')) {
        data._id = data.roomId
      }
      if (pouch) {
        await pouch.remote
          .put(data, { force: true })
          .then(async res => {
            if (typeof callback === 'function') {
              callback(res)
            }
          })
          .catch(err => {
            if (typeof callback === 'function') {
              callback(false)
            }
            console.log(err)
          })
      }
    },
    async insert(data, pouch, callback = false) {
      await pouch.remote
        .post(data)
        .then(res => {
          if (typeof callback === 'function') {
            callback(res)
          }
        })
        .catch(err => {
          if (typeof callback === 'function') {
            callback(false)
          }
          console.log(err)
        })
    },

    // link
    async connectLink(watch = true, isReplicate = true) {
      this.pouchLink = await this.connectPouchDb(process.env.VUE_APP_DB_LINK, this.user, isReplicate)
      if (watch && this.serverConnectionStatus) {
        this.linkWatcher = this.onChangePouchDb(this.pouchLink.remote, process.env.VUE_APP_DB_UPDATE_LINK)
      }
    },
    async loadLink() {
      if (this.pouchLink) {
        const db = this.pouchLink.remote
        const allDocs = await db.allDocs({
          include_docs: true,
        })
        const items = allDocs.rows.map(row => row.doc)
        await this.$store.dispatch('global/setLink', items)
        return items
      }

      return []
    },
    async updateLink(data, callback = false) {
      await this.update(data, this.pouchLink, callback)
    },
    async bulkUpdateLink(bulkData, callback = false) {
      await this.bulkUpdate(bulkData, this.pouchLink, callback)
    },
    async loadLinkById(id, remote = true) {
      let result = null
      if (this.pouchLink) {
        if (remote) {
          result = await this.pouchLink.remote.get(id)
        } else {
          result = await this.pouchLink.db.get(id)
        }
      }

      return result
    },

    // e-ordering
    async connectEordering(watch = true, isReplicate = true) {
      this.pouchEordering = await this.connectPouchDb(process.env.VUE_APP_DB_E_ORDERING, this.user, isReplicate)

      if (watch && this.serverConnectionStatus) {
        this.linkWatcher = this.onChangePouchDb(this.pouchEordering.remote, process.env.VUE_APP_DB_UPDATE_E_ORDERING)
      }
    },
    async loadEordering(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/eordering/get-list-shop`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      let allReturn = []
      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          // //console.log(response.data, '-------------------------ajsjioahjnosiahjniosni')
          allReturn = response.data.data
        })
        .catch(error => {
          allReturn = []
        })
      // if (this.pouchEordering) {
      //   const db = this.pouchEordering.remote
      //   const allDocs = await db.allDocs({
      //     include_docs: true,
      //   })
      //   const items = allDocs.rows.map(row => row.doc)
      //   const shops = items.filter(shop => !shop.hasOwnProperty('value') && !shop.hasOwnProperty('orderData'))
      //   const orders = items.filter(shop => !shop.hasOwnProperty('value') && shop.hasOwnProperty('orderData'))
      //   // await this.$store.dispatch('global/setLink', items)
      //   return { shops, orders }
      // }

      return allReturn
    },
    async loadEorderingOrder(data) {
      // //console.log(new Date(data.endDate), '===========+++++++++++++++++')
      // //console.log(this.shopList[this.currentTab]._id, '+++++++++++++++++00000000000')
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/eordering/get-list-order`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
      let allReturn = []
      // //console.log(data, 'ajsjioahjnosiahjniosni -------------------------')
      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          // //console.log(response.data, '-------------------------ajsjioahjnosiahjniosni')
          allReturn = response.data.data
        })
        .catch(error => {
          allReturn = []
        })

      // if (this.pouchEordering) {
      //   const db = this.pouchEordering.remote
      //   const allDocs = await db.allDocs({
      //     include_docs: true,
      //   })
      //   const items = allDocs.rows.map(row => row.doc)
      //   const shops = items.filter(shop => !shop.hasOwnProperty('value') && !shop.hasOwnProperty('orderData'))
      //   const orders = items.filter(shop => !shop.hasOwnProperty('value') && shop.hasOwnProperty('orderData'))
      //   // await this.$store.dispatch('global/setLink', items)
      //   return { shops, orders }
      // }

      return allReturn
    },
    async loadEorderingAllOrder(data) {
      // //console.log(new Date(data.endDate), '===========+++++++++++++++++')
      // //console.log(this.shopList[this.currentTab]._id, '+++++++++++++++++00000000000')
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/eordering/get-list-all-order`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
      let allReturn = []
      // //console.log(data, 'ajsjioahjnosiahjniosni -------------------------')
      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          // //console.log(response.data, '-------------------------ajsjioahjnosiahjniosni')
          allReturn = response.data.data
        })
        .catch(error => {
          allReturn = []
        })

      // if (this.pouchEordering) {
      //   const db = this.pouchEordering.remote
      //   const allDocs = await db.allDocs({
      //     include_docs: true,
      //   })
      //   const items = allDocs.rows.map(row => row.doc)
      //   const shops = items.filter(shop => !shop.hasOwnProperty('value') && !shop.hasOwnProperty('orderData'))
      //   const orders = items.filter(shop => !shop.hasOwnProperty('value') && shop.hasOwnProperty('orderData'))
      //   // await this.$store.dispatch('global/setLink', items)
      //   return { shops, orders }
      // }

      return allReturn
    },
    async updateEordering(data, callback = false) {
      await this.update(data, this.pouchEordering, callback)
    },
    async bulkUpdateEordering(bulkData, callback = false) {
      await this.bulkUpdate(bulkData, this.pouchEordering, callback)
    },
    async loadEorderingById(id, remote = true) {
      let result = null
      if (this.pouchEordering) {
        if (remote) {
          result = await this.pouchEordering.remote.get(id)
        } else {
          result = await this.pouchEordering.db.get(id)
        }
      }

      return result
    },

    // CHATS
    async connectChats(watch = true, isReplicate = true) {
      this.pouchChats = await this.connectPouchDb(process.env.VUE_APP_DB_CHAT_NAME, this.user, isReplicate)

      if (this.chatWatcher === null && watch && this.serverConnectionStatus) {
        this.chatWatcher = this.onChangePouchDb(this.pouchChats.remote, process.env.VUE_APP_EVENT_UPDATE_CHAT)
      }
    },

    // CONTACTS
    async connectContactsList() {
      this.pouchContactsList = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_LIST_NAME, this.user, false)
    },
    async loadContactsList() {
      if (this.pouchContactsList) {
        const db = this.pouchContactsList.remote
        const allDocs = await db.allDocs({
          include_docs: true,
        })

        const items = allDocs.rows.map(row => row.doc)

        return items
      }

      return []
    },
    async loadContactListContentById(idList) {
      this.contactListTable = []
      this.totalContact = 0
      this.currentList = null
      if (this.pouchContactsList) {
        this.loading = true

        const db = this.pouchContactsList.remote
        let selector = {
          selector: {
            $and: [
              {
                list_id: {
                  $eq: idList,
                },
              },
            ],
          },
          limit: 1,
        }

        const allDocs = await db.find(selector)
        if (allDocs.docs && allDocs.docs.length > 0) {
          this.currentList = allDocs.docs[0]
          this.contactListTable = this.currentList.contacts
          this.totalContact = this.currentList.contacts.length
        }
        this.loading = false
      }
    },
    async loadContentContactsList() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
        /['"]+/g,
        '',
      )}superwa/postgres/contact-list/get-contact-lists`
      const obj = {
        sub_id: this.user.sub_id,
      }
      try {
        let allContacts = []
        const contactLists = await this.$axios.post(endpoint, obj)
        ////console.log(contactLists.data.data,"aaaaaaaaaaaaaaaaaaaaa")
        for (var list of contactLists.data.data) {
          allContacts = allContacts.concat(
            list.contacts.map(x => {
              return {
                ...x,
                use_color: list.use_color,
                label_color: list.label_color,
                list_name: list.list_name,
                list_id: list.list_id,
              }
            }),
          )
        }
        return allContacts
      } catch (err) {
        return err
      }
      // if (this.pouchContactsList) {
      //   const db = this.pouchContactsList.remote
      //   const allDocs = await db.allDocs({
      //     include_docs: true,
      //   })

      //   let allContacts = []
      //   const items = allDocs.rows.map(row => row.doc)
      //   for (var list of items) {
      //     allContacts = allContacts.concat(
      //       list.contacts.map(x => {
      //         return {
      //           ...x,
      //           list_name: list.list_name,
      //         }
      //       }),
      //     )
      //   }

      //   return allContacts
      // }

      // return []
    },
    async updateContactList(data, callback = false) {
      await this.update(data, this.pouchContactsList, callback)
    },

    async connectMessages() {
      this.pouchMessages = await this.connectPouchDb(process.env.VUE_APP_DB_MESSAGES_NAME, this.user, false)
    },
    async connectMessagesTokopedia(watch = true) {
      this.pouchMessagesTokopedia = await this.connectPouchDb(process.env.VUE_APP_DB_CHAT_TOKOPEDIA, this.user, false)
      if (watch && this.serverConnectionStatus) {
        this.tokopediaChatWatcher = this.onChangePouchDb(
          this.pouchMessagesTokopedia.remote,
          process.env.VUE_APP_DB_CHAT_TOKOPEDIA,
        )
      }
    },
    async connectRoomTokopedia(watch = true) {
      this.pouchRoomsTokopedia = await this.connectPouchDb(process.env.VUE_APP_DB_ROOM_TOKOPEDIA, this.user, false)
      if (watch && this.serverConnectionStatus) {
        this.tokopediaRoomWatcher = this.onChangePouchDb(
          this.pouchRoomsTokopedia.remote,
          process.env.VUE_APP_DB_ROOM_TOKOPEDIA,
        )
      }
    },
    async getMessagesHistory(subId, roomId, lastMessage, callback = false) {
      if (this.pouchMessages) {
        const db = this.pouchMessages.remote
        let selector = {
          selector: {
            $and: [
              {
                subId: {
                  $eq: subId,
                },
              },
              {
                roomId: {
                  $eq: roomId,
                },
              },
            ],
          },
          sort: [
            {
              lastTimestamp: 'desc',
            },
          ],
          limit: 1,
        }
        let selector2 = {
          selector: {
            $and: [
              {
                subId: {
                  $eq: subId,
                },
              },
              {
                roomId: {
                  $eq: roomId,
                },
              },
              {
                lastTimestamp: {
                  $lt: lastMessage,
                },
              },
            ],
          },
          sort: [
            {
              lastTimestamp: 'desc',
            },
          ],
          limit: 1,
        }
        const allDocs = await db.find(lastMessage ? selector2 : selector)
        let allMessages = []
        let curLastMessage = null
        if (allDocs.docs.length > 0) {
          curLastMessage = allDocs.docs[0].firstTimestamp
          allMessages = allDocs.docs[0].dataMessage
        }

        if (callback) {
          callback({ messages: allMessages, lastMessages: curLastMessage })
        }
        return { messages: allMessages, lastMessages: curLastMessage }
      }
    },
    async getMessagesHistoryTokopedia(subId, room, lastMessage, callback = false) {
      // //console.log(this.pouchMessagesTokopedia, room, `INI POUCH TOKPED CHAT`)
      if (this.pouchMessagesTokopedia && room.msg_id) {
        const db = this.pouchMessagesTokopedia.remote
        const payloadGetChat = {
          sub_id: subId,
          clientId: room.clientId,
          clientSecret: room.clientSecret,
          fs_id: room.fs_id,
          msg_id: room.msg_id.toString(),
          shop_id: room.shop_id.toString(),
        }

        await this.$store.dispatch('auth/getTokopediaChat', payloadGetChat)
        // let selector = {
        //   selector: {
        //     $and: [
        //       {
        //         subId: {
        //           $eq: subId,
        //         },
        //       },
        //       {
        //         roomId: {
        //           $eq: roomId,
        //         },
        //       },
        //     ],
        //   },
        //   sort: [
        //     {
        //       lastTimestamp: 'desc',
        //     },
        //   ],
        //   limit: 1,
        // }
        // let selector2 = {
        //   selector: {
        //     $and: [
        //       {
        //         subId: {
        //           $eq: subId,
        //         },
        //       },
        //       {
        //         roomId: {
        //           $eq: roomId,
        //         },
        //       },
        //       {
        //         lastTimestamp: {
        //           $lt: lastMessage,
        //         },
        //       },
        //     ],
        //   },
        //   sort: [
        //     {
        //       lastTimestamp: 'desc',
        //     },
        //   ],
        //   limit: 1,
        // }
        const allDocs = await db.find({ selector: { msg_id: room.msg_id.toString() } })
        // //console.log(allDocs.docs[0], room, `INI DOCS NYA`)
        let allMessages = []
        let curLastMessage = null
        if (allDocs.docs.length > 0) {
          curLastMessage = allDocs?.docs[0]?.dataMessage[0]
          allMessages = allDocs.docs[0]?.dataMessage
        }

        if (callback) {
          callback({ messages: allMessages, lastMessages: curLastMessage })
        }
        return { messages: allMessages, lastMessages: curLastMessage }
      }
    },
    // CONTACTS
    async connectContacts(watch = true, isReplicate = true) {
      this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, isReplicate)

      if (this.contactWatcher === null && watch && this.serverConnectionStatus) {
        this.contactWatcher = this.onChangePouchDb(this.pouchContacts.remote, process.env.VUE_APP_EVENT_UPDATE_CONTACTS)
      }
    },
    async compactContacts() {
      //console.log('compact start')
      return this.pouchContacts.db
        .compact()
        .then(function (info) {
          // compaction complete
          //console.log('compact completed')
        })
        .catch(function (err) {
          // handle errors
          console.log(err, 'compact err')
        })
    },
    async loadContactsChange() {
      if (this.pouchContacts) {
        const lastSeq = this.$store.getters['global/getLastSeq']
        const self = this
        await this.pouchContacts.remote
          .changes({
            since: lastSeq === 0 ? 'now' : lastSeq,
            live: false,
            include_docs: true,
          })
          .then(async function (changes) {
            if (changes.results.length > 0) {
              for (var data of changes.results) {
                const idx = self.allContactsGlobal.findIndex(x => x._id === data.id)
                if (idx > -1) {
                  self.allContactsGlobal[idx] = data.doc
                } else {
                  self.allContactsGlobal.push(data.doc)
                }
              }
            }
            self.$store.dispatch('global/setLastSeq', changes.last_seq)
            self.contactsPouch = [...self.allContactsGlobal]
            await self.getInbox(true)

            if (self.selectedSettingFilter !== null) self.filterRooms(self.roomsVuex)

            if (self.$route.params && self.$route.params.data) {
              self.openChat(self.$route.params.data)
            }

            self.$store.dispatch('global/setSyncFromServer', false)
          })
          .catch(function (err) {
            self.$store.dispatch('global/setSyncFromServer', false)
          })
        return self.allContactsGlobal
      }
      return this.allContactsGlobal
    },
    async loadChats(remote = false, callback = false) {
      // let replicateStatus = await this.checkReplicateContacts()
      if (!this.pouchChats) {
        this.pouchChats = await this.connectPouchDb(process.env.VUE_APP_EVENT_UPDATE_CHAT, this.user, false)
      }

      // if (this.pouchContacts) {
      // let db = replicateStatus.percent === 100 ? this.pouchContacts.db : this.pouchContacts.remote
      let db = this.pouchChats.db
      if (remote) db = this.pouchChats.remote
      const allDocs = await db.allDocs({
        include_docs: true,
      })

      const items = allDocs.rows.map(row => row.doc)

      if (callback) {
        callback(items)
      }
      return items
    },
    async loadContacts(remote = false, isTransform = false, callback = false) {
      // let replicateStatus = await this.checkReplicateContacts()
      if (!this.pouchContacts) {
        this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      }

      // if (this.pouchContacts) {
      // let db = replicateStatus.percent === 100 ? this.pouchContacts.db : this.pouchContacts.remote
      let db = this.pouchContacts.db
      if (remote) db = this.pouchContacts.remote
      const allDocs = await db.allDocs({
        include_docs: true,
      })

      // transform
      if (isTransform) {
        const items = allDocs.rows.map(row => row.doc)
        const contacts = items.filter(doc => !doc.roomId)
        const rooms = items.filter(doc => doc.roomId)
        this.allContactsGlobal = contacts

        return {
          contacts,
          rooms,
        }
      } else {
        const items = allDocs.rows.map(row => row.doc)
        this.allContactsGlobal = items

        if (callback) {
          callback(items)
        }
        return items
      }
    },
    async loadContactsLastMessage(remote = false, callback = false) {
      // let replicateStatus = await this.checkReplicateContacts()
      if (!this.pouchContacts) {
        this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      }

      if (!this.pouchRoomsTokopedia) {
        this.pouchRoomsTokopedia = await this.connectPouchDb(process.env.VUE_APP_DB_ROOM_TOKOPEDIA, this.user, false)
      }

      let items, itemsTokopedia

      if (this.pouchContacts) {
        // let db = replicateStatus.percent === 100 ? this.pouchContacts.db : this.pouchContacts.remote
        let db = this.pouchContacts.db
        if (remote) db = this.pouchContacts.remote
        // const allDocs = await db.allDocs({
        //   include_docs: true,
        // })
        let selector = {
          selector: {
            lastMessage: {
              $ne: null,
            },
          },
          limit: 999999,
        }

        const allDocs = await db.find(selector)

        items = allDocs.docs
      }
      if (this.pouchRoomsTokopedia) {
        // let db = replicateStatus.percent === 100 ? this.pouchRoomsTokopedia.db : this.pouchRoomsTokopedia.remote
        let db = this.pouchRoomsTokopedia.db
        if (remote) db = this.pouchRoomsTokopedia.remote
        // const allDocs = await db.allDocs({
        //   include_docs: true,
        // })
        let selector = {
          selector: {
            shop_id: {
              $ne: null,
            },
          },
          limit: 999999,
        }

        const allDocs = await db.find(selector)
        itemsTokopedia = allDocs.docs
      }

      if (callback) {
        callback(items, itemsTokopedia)
      }
      // //console.log(items, itemsTokopedia, `INI ITEMS TOKPEDA`)
      return { items, itemsTokopedia }
    },
    async loadContactsRoomsAll(remote = false, callback = false) {
      // let replicateStatus = await this.checkReplicateContacts()
      if (!this.pouchContacts) {
        this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      }

      if (!this.pouchRoomsTokopedia) {
        this.pouchRoomsTokopedia = await this.connectPouchDb(process.env.VUE_APP_DB_ROOM_TOKOPEDIA, this.user, false)
      }

      // if (this.pouchContacts) {
      // let db = replicateStatus.percent === 100 ? this.pouchContacts.db : this.pouchContacts.remote
      let db = this.pouchContacts.db
      if (remote) db = this.pouchContacts.remote
      // const allDocs = await db.allDocs({
      //   include_docs: true,
      // })
      let selector = {
        selector: {
          phone_number: {
            $ne: null,
          },
          name: {
            $ne: null,
          },
        },
        fields: ['_id', '_rev', 'phone_number', 'name', 'instance_id', 'profile_picture'],
        limit: 999999,
      }

      const allDocs = await db.find(selector)

      const items = allDocs.docs

      if (callback) {
        callback(items)
      }
      // }
      return items
    },
    async loadContactsRooms(remote = false, instances = [], callback = false) {
      // let replicateStatus = await this.checkReplicateContacts()
      if (!this.pouchContacts) {
        this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      }

      // if (this.pouchContacts) {
      // let db = replicateStatus.percent === 100 ? this.pouchContacts.db : this.pouchContacts.remote
      let db = this.pouchContacts.db
      if (remote) db = this.pouchContacts.remote
      // const allDocs = await db.allDocs({
      //   include_docs: true,
      // })
      let finalItem = []

      let selector1 = {
        selector: {
          $or: [
            {
              unreplied: {
                $eq: true,
              },
            },
            {
              assign_to: {
                $ne: null,
              },
            },
            {
              pinned: {
                $eq: 1,
              },
            },
            {
              archived: {
                $eq: true,
              },
            },
          ],
        },
      }

      const allDocs1 = await db.find(selector1)
      finalItem = finalItem.concat(allDocs1.docs)
      const maxLimit =
        instances.length > 0 && Math.ceil(100 / instances.length) > 25
          ? Math.ceil(100 / instances.length)
          : instances.length > 0
          ? 25
          : 100
      for (var instance of instances) {
        let selector = {
          selector: {
            lastMessage: {
              $ne: null,
            },
            last_message: {
              $ne: null,
            },
            instance_id: {
              $eq: instance._id,
            },
          },
          sort: [
            {
              last_message: 'desc',
            },
          ],
          limit: maxLimit,
        }

        const allDocs = await db.find(selector)
        finalItem = finalItem.concat(allDocs.docs)
      }

      finalItem = finalItem.filter(
        (room, index, that) =>
          index === that.findIndex(t => t !== undefined && room !== undefined && t.roomId === room.roomId),
      )

      this.allContactsGlobal = finalItem

      if (callback) {
        callback(finalItem)
      }
      return finalItem
    },
    async checkReplicateChats(forceRemoteCheck = false) {
      if (!this.pouchChats) {
        this.pouchChats = await this.connectPouchDb(process.env.VUE_APP_DB_CHAT_NAME, this.user, false)
      }
      // let local = await this.pouchContacts.db.allDocs({ include_docs: false })
      let local = await this.pouchChats.db.info()
      let remote = { doc_count: 0 }
      if (!this.remoteChatsCount || forceRemoteCheck) {
        // this.remoteContactsCount = await this.pouchContacts.remote.allDocs({ include_docs: false })
        this.remoteChatsCount = await this.pouchChats.remote.info()
      }
      remote = this.remoteChatsCount

      let diff =
        remote.doc_count > local.doc_count ? remote.doc_count - local.doc_count : local.doc_count - remote.doc_count
      return {
        diff: diff,
        percent: remote.doc_count === 0 ? 100 : Math.ceil(100 - (diff / remote.doc_count) * 100),
        noContact: remote.doc_count === 1,
      }
    },
    async checkReplicateContacts(forceRemoteCheck = false) {
      if (!this.pouchContacts) {
        this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      }
      // let local = await this.pouchContacts.db.allDocs({ include_docs: false })
      let local = await this.pouchContacts.db.info()
      let remote = { doc_count: 0 }
      if (!this.remoteContactsCount || forceRemoteCheck) {
        // this.remoteContactsCount = await this.pouchContacts.remote.allDocs({ include_docs: false })
        this.remoteContactsCount = await this.pouchContacts.remote.info()
      }
      remote = this.remoteContactsCount

      let diff = remote.doc_count - local.doc_count
      return {
        diff: diff,
        percent: remote.doc_count === 0 ? 100 : Math.ceil(100 - (diff / remote.doc_count) * 100),
        noContact: remote.doc_count === 1,
      }
    },
    async loadContactsv2(remote = false) {
      if (this.pouchContacts) {
        const db = remote ? this.pouchContacts.remote : this.pouchContacts.db
        let selector = {
          selector: {
            ch: {
              $ne: null,
            },
          },
          sort: [
            {
              last_message: 'desc',
            },
          ],
          limit: 100,
        }
        const allDocs = await db.find(selector)

        const items = allDocs.docs
        this.allContactsGlobal = items

        return items
      }

      return []
    },
    async bulkUpdateContact(bulkData, callback = false) {
      this.bulkUpdate(bulkData, this.pouchContacts, callback)
    },
    async updateContact(data, callback = false) {
      this.update(data, this.pouchContacts, callback)
    },

    // SETTINGS
    async connectSettings(watch = true, isReplicate = true) {
      this.pouchSettings = await this.connectPouchDb(process.env.VUE_APP_DB_SETTING_NAME, this.user, isReplicate)
      if (this.settingWatcher === null && watch && this.serverConnectionStatus) {
        this.settingWatcher = this.onChangePouchDb(this.pouchSettings.remote, process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
      }
    },
    async loadSettings() {
      if (this.pouchSettings) {
        const db = this.pouchSettings.remote
        const allDocs = await db.allDocs({
          include_docs: true,
        })

        const items = allDocs.rows.map(row => row.doc)
        await this.$store.dispatch('global/setSettings', items)

        return items
      }

      return []
    },
    async mappingSettingsContacts(idlist, items) {
      const result = {
        allSettings: null,
        columnsSetting: null,
        tableColumns: [],
        itemsBreadcrumbs: [
          {
            text: 'Contact',
            disabled: false,
            exact: true,
            link: true,
            to: {
              name: 'contacts-group-list',
            },
          },
        ],
        labelData: [],
        labelSetting: null,
        labelValue: [],
        settingFilterSetting: null,
        itemsFilter: [],
      }

      result.allSettings = items
      const colSetting = items.find(x => x._id === 'columns')
      const tempTableValue =
        colSetting && colSetting.value
          ? colSetting.value.filter(
              x => x.value === 'phone_number' || x.value === 'phone_number_show' || x.value === 'name',
            )
          : []
      if (colSetting && !idlist) {
        result.columnsSetting = colSetting
        result.tableColumns = colSetting.value
      }
      if (idlist) {
        const colContactList = await this.loadContactsList()
        const filteredContactList = colContactList.find(x => x.list_id === idlist)
        if (filteredContactList) {
          let listName = idlist
          if (filteredContactList.list_name) listName = filteredContactList.list_name
          result.itemsBreadcrumbs.push({
            text: listName,
            disabled: true,
            href: '',
          })
        }
        const colSettingList = items.find(x => x._id === `${this.idlist}columns`)
        if (colSettingList) {
          result.columnsSetting = colSettingList
          result.tableColumns = colSettingList.value
        } else {
          await this.updateSettings(
            {
              _id: `${idlist}columns`,
              key: `${idlist}columns`,
              value: tempTableValue,
            },
            async x => {
              result.columnsSetting = await this.loadSettingsById(x.id)
              result.tableColumns = result.columnsSetting.value
            },
          )
        }
      }

      if (colSetting && colSetting.value) {
        const labelColumn = colSetting.value.find(x => x.value === 'label')
        if (labelColumn) {
          result.labelData = labelColumn.listValue
        }
      }

      result.labelSetting = items.find(x => x._id === 'label')
      if (!result.labelSetting) {
        await this.updateSettings({ _id: 'label', key: 'label', value: [] }, async x => {
          result.labelSetting = await this.loadSettingsById(x.id)
          result.labelValue = result.labelSetting.value
        })
      } else {
        result.labelValue = result.labelSetting.value
      }

      result.settingFilterSetting = items.find(x => x._id === 'setting_filter')
      if (result.settingFilterSetting) {
        result.itemsFilter = result.settingFilterSetting.value.map(x => ({
          label: x.name,
          value: x,
        }))
      }

      return result
    },
    async loadSettingContactsList(items) {
      const obj = {
        labelData: [],
        contactListSetting: null,
        listContactValue: [],
      }
      const colSetting = items.find(x => x._id === 'columns')
      if (colSetting) {
        const labelColumn = colSetting.value.find(x => x.value === 'label')
        if (labelColumn) {
          obj.labelData = labelColumn.listValue
        }
      }

      obj.contactListSetting = items.find(x => x._id === 'contact_list')
      if (!obj.contactListSetting) {
        await this.updateSettings(
          {
            _id: 'contact_list',

            key: 'contact_list',
            value: [],
          },
          async x => {
            obj.contactListSetting = await this.loadSettingsById(x.id)
            obj.listContactValue = obj.contactListSetting.value
          },
        )
      } else {
        obj.listContactValue = obj.contactListSetting.value
      }

      return obj
    },
    async loadSettingLabel(items) {
      const obj = {
        columnsSetting: null,
        tableColumns: [],
        labelData: [],
        labelSetting: null,
        labelValue: [],
      }
      obj.columnsSetting = items.find(x => x._id === 'columns')
      if (obj.columnsSetting) obj.tableColumns = obj.columnsSetting.value
      const labelColumn = obj.tableColumns.find(x => x.value === 'label')
      if (labelColumn) {
        obj.labelData = labelColumn.listValue
      }

      obj.labelSetting = items.find(x => x._id === 'label')
      if (!obj.labelSetting) {
        await this.updateSettings({ _id: 'label', key: 'label', value: [] }, async x => {
          obj.labelSetting = await this.loadSettingsById(x.id)
          obj.labelValue = obj.labelSetting.value
        })
      } else {
        obj.labelValue = obj.labelSetting.value
      }

      return obj
    },
    async mappingSettingChannels(items) {
      const obj = {
        instances: null,
        syncSetting: null,
        muteNotificationSetting: null,
        boldUnrepliedSetting: null,
        autoAssignSetting: null,
        autoAssignNewChatSetting: null,
        notifAllOrAssignedSetting: null,
        autoCloseSetting: null,
      }

      obj.instances = items.find(item => {
        return item.key == 'instances'
      })

      obj.syncSetting = items.find(item => {
        return item.key == 'sync_setting'
      })

      obj.muteNotificationSetting = items.find(item => {
        return item.key == 'mute_notification'
      })

      obj.boldUnrepliedSetting = items.find(item => {
        return item.key == 'bold_unreplied'
      })

      obj.autoAssignSetting = items.find(item => {
        return item.key == 'auto_assign'
      })

      obj.autoAssignNewChatSetting = items.find(item => {
        return item.key == 'auto_assign_new_chat'
      })

      obj.notifAllOrAssignedSetting = items.find(item => {
        return item.key == 'notif_all_or_assigned'
      })

      obj.autoCloseSetting = items.find(item => {
        return item.key == 'auto_close'
      })

      return obj
    },
    async loadSettingsById(id, remote = true) {
      let result = null
      if (!this.pouchSettings) {
        this.pouchSettings = await this.connectPouchDb(process.env.VUE_APP_DB_SETTING_NAME, this.user, false)
      }
      if (this.pouchSettings) {
        try {
          if (remote) {
            result = await this.pouchSettings.remote.get(id)
          } else {
            result = await this.pouchSettings.db.get(id)
          }
        } catch (error) {
          console.log(error, 'remote : ', remote)
          return result
        }
      }

      return result
    },
    async bulkUpdateSettings(bulkData, callback = false) {
      await this.bulkUpdate(bulkData, this.pouchSettings, callback)
    },
    async updateSettings(data, callback = false) {
      await this.update(data, this.pouchSettings, callback)
    },
    async mixinLoadSettingsRemote() {
      if (!this.pouchSettings) {
        this.pouchSettings = await this.connectPouchDb(process.env.VUE_APP_DB_SETTING_NAME, this.user, false)
      }
      const allDocs = await this.pouchSettings.remote.allDocs({
        include_docs: true,
      })

      const items = allDocs.rows.map(row => row.doc)

      return items

      // return []
    },

    // MESSAGE TEMPLATE
    async mixinUpdateMessageTemplate(value, user) {
      let tempData = null
      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: user.sub_id,
        token: user.token,
        key: 'message_template',
      })

      if (messageTemplatePg.status) {
        tempData = messageTemplatePg.data.value
      }
      if (tempData) {
        tempData = value
        try {
          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'message_template',
            value: tempData,
          })

          return true
        } catch (error) {
          console.log(error)

          return false
        }
      }
    },
    async mixinUpdateCategoryMessage(value, user) {
      let tempData = null
      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: user.sub_id,
        token: user.token,
        key: 'message_template',
      })

      if (messageTemplatePg.status) {
        tempData = messageTemplatePg.data.value
      }
      if (tempData) {
        tempData = value
        try {
          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'message_template',
            value: tempData,
          })

          return true
        } catch (error) {
          console.log(error)

          return false
        }
      }
    },
    async mixinSaveTemplate(value, user) {
      let tempData = null
      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: user.sub_id,
        token: user.token,
        key: 'message_template',
      })

      if (messageTemplatePg.status) {
        tempData = messageTemplatePg.data.value
      }
      if (tempData) {
        tempData.push(value)
        try {
          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'message_template',
            value: tempData,
          })

          return true
        } catch (error) {
          console.log(error)

          return false
        }
      }
    },
    async mixinEditTemplate(value, user) {
      let tempData = null
      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: user.sub_id,
        token: user.token,
        key: 'message_template',
      })

      if (messageTemplatePg.status) {
        tempData = messageTemplatePg.data.value
      }
      if (tempData) {
        const index = _findIndex(tempData, function (x) {
          return x.id === value.id
        })
        tempData[index] = value
        try {
          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'message_template',
            value: tempData,
          })

          return true
        } catch (error) {
          console.log(error)

          return false
        }
      }
    },
    async mixinOnchangeTemplateLocal(payload) {
      try {
        await this.pouchSettings.remote.put(payload, { force: true })

        return true
      } catch (error) {
        console.log(error)

        return false
      }
    },

    // HISTORY
    async connectHistory(watch = true, isReplicate = true) {
      this.pouchHistory = await this.connectPouchDb(process.env.VUE_APP_DB_HISTORY_NAME, this.user, isReplicate)

      if (watch && this.serverConnectionStatus) {
        this.historyWatcher = this.onChangePouchDb(this.pouchHistory.remote, process.env.VUE_APP_EVENT_UPDATE_HISTORY)
      }
    },
    async loadHistory() {
      if (this.pouchHistory) {
        const allDocs = await this.pouchHistory.remote.allDocs({
          include_docs: true,
        })

        const items = allDocs.rows.map(row => row.doc)

        return items
      }

      return []
    },
    async loadHistoryById(id) {
      if (this.pouchHistory) {
        const result = await this.pouchHistory.remote.get(id)

        return result
      }

      return null
    },
    async bulkUpdateHistory(bulkData, callback = false) {
      this.bulkUpdate(bulkData, this.pouchHistory, callback)
    },
    async updateHistory(data, callback = false) {
      this.update(data, this.pouchHistory, callback)
    },
    async insertHistory(data, callback = false) {
      this.insert(data, this.pouchHistory, callback)
    },
    async loadHistoryOperator() {
      if (!this.pouchHistory) {
        this.pouchHistory = await this.connectPouchDb(process.env.VUE_APP_DB_HISTORY_NAME, this.user)
      }

      try {
        const data = await this.pouchHistory.remote.allDocs({ include_docs: true })
        const items = data.rows.map(row => row.doc)
        const historyListTable = []

        items.forEach(item => {
          const obj = {
            email: '',
            timestamp: '',
            chat: '',
            to: '',
            reply_time: '',
            latest_message: [],
            room: null,
          }

          obj.email = item.email
          obj.latest_message = item.latest_message

          if (item.chat) {
            obj.timestamp = item.chat.messageTimestamp

            obj.chat = item.chat.caption

            if (item.chat.contactName) {
              obj.to = item.chat.contactName
            } else {
              obj.to = item.latest_message[1].contactName
            }
          }

          if (item.room) {
            obj.room = item.room
          }

          let replyTime = 0

          if (item.latest_message.length > 1) {
            if (item.latest_message[0].messageTimestamp > item.latest_message[1].messageTimestamp) {
              replyTime = item.latest_message[0].messageTimestamp - item.latest_message[1].messageTimestamp
            }
          }

          obj.reply_time = replyTime

          historyListTable.push(obj)
        })

        return historyListTable
      } catch (err) {
        console.error(err)

        return []
      }
    },

    async loadHistoryOperatorFirestore(start = null, end = null) {
      if (!start) start = new Date(new Date().setDate(new Date().getDate() - 1))
      if (!end) end = new Date()

      try {
        const historyRef = collectionGroup(firestoreDb, 'messages')
        const queryData = query(
          historyRef,
          where('subId', '==', this.user.sub_id),
          where('history', '==', true),
          where('timestamp', '>=', Timestamp.fromDate(start)),
          where('timestamp', '<=', Timestamp.fromDate(end)),
          orderBy('timestamp', 'desc'),
        )
        const data = await getDocs(queryData)
        //console.log(data.empty, 'data.empty')
        if (data.empty) {
          return []
        }

        const historyListTable = []
        let timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000

        data.forEach(item => {
          let username = ''
          let avatar = ''
          let phone = ''

          if (item.data().contact) {
            username = item.data().contact.name
            avatar = item.data().contact.avatar
            phone = item.data().contact.phone
          }

          let latestMessage = item.data().dataMessage.map(mess => {
            let objMess = { ...mess }

            objMess.timestamp = new Date(mess.timestamp.seconds * 1000 - timezoneOffset).toISOString().substring(11, 16)
            objMess.dateHistory = new Date(mess.timestamp.seconds * 1000 - timezoneOffset).toString()
            objMess._id = mess.source_id
            objMess.senderId = mess.sender_id
            objMess.system = false
            objMess.new = false
            objMess.username = mess.fromMe ? item.data().channel.name : username

            return objMess
          })

          const obj = {
            messageId: item.id,
            messagePath: item.ref.path,
            email: item.data().assign_to.email ? item.data().assign_to.email : '-',
            timestamp: item.data().lastTimestamp / 1000,
            chat: '',
            contact: username,
            currentUserId: item.data().channel.identifier,
            reply_time: item.data().totalReplyTime / item.data().totalCountReply,
            latest_message: latestMessage,
            room: null,
            channel: `${item.data().channel.name} - ${item.data().channel.identifier}`,
            duration: (item.data().lastTimestamp - item.data().firstTimestamp) / 1000,
            rating: item.data().rating ? item.data().rating : 0,
          }

          obj.room = {
            roomId: item.data().instanceId + '-' + item.data().contact.phone,
            roomName: username,
            avatar: avatar,
            unreadCount: 0,
            index: 1,
            lastMessage: {
              content: latestMessage[latestMessage.length - 1].content,
              senderId: latestMessage[latestMessage.length - 1].sender_id,
              username: latestMessage[latestMessage.length - 1].username,
              timestamp: latestMessage[latestMessage.length - 1].timestamp,
              saved: false,
              distributed: false,
              seen: false,
              new: false,
            },
            users: [phone],
            typingUsers: [],
          }

          historyListTable.push(obj)
        })
        return historyListTable
      } catch (err) {
        console.error(err)

        return []
      }
    },

    async loadHistoryOperatorCouch(start = null, end = null) {
      if (!start) start = new Date(new Date().setDate(new Date().getDate() - 1))
      if (!end) end = new Date()

      try {
        const messageRef = await this.connectPouchDb(process.env.VUE_APP_DB_MESSAGES_NAME, this.user, false)
        // const historyRef = nano.use(this.user.sub_id+"_messages")
        const query = {
          selector: {
            timestamp: {
              _seconds: {
                $gte: Math.floor(start.getTime() / 1000),
                $lte: Math.floor(end.getTime() / 1000),
              },
            },
            history: true,
          },
          sort: [
            {
              'timestamp._seconds': 'desc',
            },
          ],
          limit: 999999,
        }
        const data = await messageRef.remote.find(query)
        if (data.docs.length == 0) {
          return []
        }

        const historyListTable = []
        let timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000

        data.docs.forEach(item => {
          let username = ''
          let avatar = ''
          let phone = ''

          if (item.contact) {
            username = item.contact.name
            avatar = item.contact.avatar
            phone = item.contact.phone
          }

          let latestMessage = item.dataMessage.map(mess => {
            let objMess = { ...mess }

            objMess.timestamp = new Date(mess.timestamp._seconds * 1000 - timezoneOffset)
              .toISOString()
              .substring(11, 16)
            objMess.dateHistory = new Date(mess.timestamp._seconds * 1000 - timezoneOffset).toString()
            objMess._id = mess.source_id
            objMess.senderId = mess.sender_id
            objMess.system = false
            objMess.new = false
            objMess.username = mess.fromMe ? item.channel.name : username

            return objMess
          })

          const obj = {
            table_id: this.$nanoid(),
            messageId: item.id,
            // messagePath: item.ref.path,
            email: item.assign_to.email ? item.assign_to.email : '-',
            timestamp: item.lastTimestamp / 1000,
            chat: '',
            contact: username,
            currentUserId: item.channel.identifier,
            reply_time: item.totalReplyTime / item.totalCountReply,
            latest_message: latestMessage,
            room: null,
            channel: `${item.channel.name} - ${item.channel.identifier}`,
            duration: (item.lastTimestamp - item.firstTimestamp) / 1000,
            rating: item.rating ? item.rating : 0,
          }

          obj.room = {
            roomId: item.instanceId + '-' + item.contact.phone,
            roomName: username,
            avatar: avatar,
            unreadCount: 0,
            index: 1,
            lastMessage: {
              content: latestMessage[latestMessage.length - 1].content,
              senderId: latestMessage[latestMessage.length - 1].sender_id,
              username: latestMessage[latestMessage.length - 1].username,
              timestamp: latestMessage[latestMessage.length - 1].timestamp,
              saved: false,
              distributed: false,
              seen: false,
              new: false,
            },
            users: [phone],
            typingUsers: [],
          }

          historyListTable.push(obj)
        })
        return historyListTable
      } catch (err) {
        console.error(err)

        return []
      }
    },

    async setRatingHistoryOperatorFirestore(item) {
      try {
        // const messagesRef = collectionGroup(firestoreDb, 'messages')
        const messageRef = doc(firestoreDb, item.messagePath)

        await updateDoc(messageRef, {
          rating: item.rating,
        })

        //console.log(item)
      } catch (err) {
        console.error(err)
      }
    },

    // BROADCAST
    async mixinConnectBroadcasts(isReplicate = false) {
      this.pouchBroadcast = await this.connectPouchDb(process.env.VUE_APP_DB_BROADCASTS_NAME, this.user, isReplicate)

      if (this.broadcastWatcher === null && this.serverConnectionStatus) {
        this.broadcastWatcher = this.onChangePouchDb(
          this.pouchBroadcast.remote,
          process.env.VUE_APP_EVENT_UPDATE_BROADCAST,
        )
      }
    },
    async mixinLoadBroadcasts(filterBroadcast = 'all', remote = false) {
      if (!this.pouchBroadcast) {
        this.pouchBroadcast = await this.connectPouchDb(process.env.VUE_APP_DB_BROADCASTS_NAME, this.user)
      }
      let opt = { include_docs: true }
      let selector = {
        selector: { status: 'Waiting' },
      }
      let dbSelect = remote ? 'remote' : 'db'

      let resData = { rows: [] }
      try {
        if (filterBroadcast === 'all') resData = (await this.pouchBroadcast[dbSelect].allDocs(opt)).rows
        else resData = (await this.pouchBroadcast[dbSelect].find(selector)).docs.map(el => ({ doc: el }))

        return this.mixinRestructureDataBroadcastBulk(resData)
      } catch (error) {
        console.log(error)

        return []
      }
    },
    mixinRestructureDataBroadcastBulk(resData) {
      const broadcasts = []
      resData.forEach(row => {
        row.doc.broadcast_time = new Date(row.doc.broadcast_time - new Date().getTimezoneOffset() * 60 * 1000)
          .toISOString()
          .substring(0, 16)
          .replace('T', ' ')
        let tempRead = 0
        // change status in detail broadcast
        row.doc.contacts = row.doc.contacts.map(el => {
          el.errInfo = row.doc.info ? row.doc.info[el.phone_number] || (row.doc.is_draft ? 'Draft' : 'Waiting') : '-'
          el.status = !row.doc.receiver[el.phone_number]
            ? this.$t('broadcasts.unsend')
            : row.doc.receiver[el.phone_number].status == 0 && el.errInfo == 'Waiting'
            ? this.$t('broadcasts.unsend')
            : row.doc.receiver[el.phone_number].status == 0
            ? this.$t('broadcasts.failed')
            : row.doc.receiver[el.phone_number].status === 1
            ? this.$t('broadcasts.sent')
            : row.doc.receiver[el.phone_number].status === 2
            ? this.$t('broadcasts.received')
            : row.doc.receiver[el.phone_number].status === 3
            ? this.$t('broadcasts.read')
            : row.doc.receiver[el.phone_number].status === 4
            ? this.$t('broadcasts.replied')
            : this.$t('broadcasts.unknown')

          if (row.doc.receiver[el.phone_number]) {
            if (row.doc.receiver[el.phone_number].status >= 3) tempRead += 1
          }
          return el
        })

        row.doc.progress =
          Math.floor(((row.doc.sent + row.doc.failed) / row.doc.contacts.length) * 100) > 100
            ? Math.floor(100)
            : Math.floor(((row.doc.sent + row.doc.failed) / row.doc.contacts.length) * 100)

        row.doc.channel = row.doc.sender.label
        row.doc.template = row.doc.message_template.template_name
        row.doc.status = row.doc.is_draft ? 'Draft' : row.doc.is_paused ? 'Paused' : row.doc.status
        row.doc.statistic = {
          sent: row.doc.sent,
          received: row.doc.received,
          read: tempRead,
          replied: row.doc.replied,
        }
        broadcasts.push(row.doc)
      })

      // sorting desc
      let sortedBroadcast = _orderBy(broadcasts, ['broadcast_time'], ['desc'])
      sortedBroadcast = sortedBroadcast.map(el => {
        el.id = el._id

        return el
      })
      return sortedBroadcast
    },
    mixinRestructureDataBroadcast(payload) {
      // //console.log("============ onchange broadcast triggered ====== ", payload);
      const obj = { ...payload }
      obj.broadcast_time = new Date(payload.broadcast_time - new Date().getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .substring(0, 16)
        .replace('T', ' ')

      // change status in detail broadcast
      let tempRead = 0
      obj.contacts = payload.contacts.map(el => {
        el.errInfo = payload.info ? payload.info[el.phone_number] || (payload.is_draft ? 'Draft' : 'Waiting') : '-'
        el.status = !payload.receiver[el.phone_number]
          ? this.$t('broadcasts.unsend')
          : payload.receiver[el.phone_number].status == 0 && el.errInfo == 'Waiting'
          ? this.$t('broadcasts.unsend')
          : payload.receiver[el.phone_number].status == 0
          ? this.$t('broadcasts.failed')
          : payload.receiver[el.phone_number].status === 1
          ? this.$t('broadcasts.sent')
          : payload.receiver[el.phone_number].status === 2
          ? this.$t('broadcasts.received')
          : payload.receiver[el.phone_number].status === 3
          ? this.$t('broadcasts.read')
          : payload.receiver[el.phone_number].status === 4
          ? this.$t('broadcasts.replied')
          : this.$t('broadcasts.unknown')

        if (payload.receiver[el.phone_number]) {
          if (payload.receiver[el.phone_number].status >= 3) tempRead += 1
        }
        return el
      })

      obj.progress = Math.floor(((obj.sent + obj.failed) / obj.contacts.length) * 100)

      obj.channel = payload.sender.label
      obj.template = payload.message_template.template_name
      obj.status = payload.is_draft ? 'Draft' : payload.is_paused ? 'Paused' : payload.status
      obj.statistic = {
        sent: payload.sent,
        received: payload.received,
        read: tempRead,
        replied: payload.replied,
      }

      return obj
    },
    async mixinRestructureOnchangeBroadcast(list, payload) {
      const deleted = !!payload.deleted
      const newData = payload.doc._rev.split('-')[0] === '1'

      // get index data
      let temp = [...list]
      if (newData && !deleted) {
        const data = this.mixinRestructureDataBroadcast(payload.doc)
        temp = _orderBy([data, ...list], ['broadcast_time'], ['desc'])
      } else {
        const index = _findIndex(list, function (x) {
          return x._id === payload.doc._id
        })
        if (deleted && index > -1) {
          temp.splice(index, 1)
        } else {
          const data = this.mixinRestructureDataBroadcast(payload.doc)
          temp[index] = data
        }
      }
      this.replicateFromPouchDbGlobal('broadcasts', this.user)
      return temp
    },
    async checkStatusConnectionServer() {
      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '')}~health`

      try {
        await this.$axiosLimit.get(endpoint)
        this.$store.commit('global/setServerConnectionStatus', true)
        return true
      } catch (error) {
        // console.log(error, '=== check conn')
        this.$store.commit('global/setServerConnectionStatus', false)
        return false
      }
    },
    async addContactListColumn(id) {
      let column = [
        {
          id: 1,
          name: 'id',
          align: 'center',
          label: 'Id',
          field: 'id',
          value: 'id',
          text: 'Id',
          sortable: true,
          type: 'number',
          show: false,
          showable: false,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: false,
          order_number: 1,
        },
        {
          id: 2,
          name: 'name',
          align: 'left',
          label: 'Name',
          field: 'name',
          value: 'name',
          text: 'Name',
          sortable: true,
          type: 'text',
          show: true,
          showable: false,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: true,
          order_number: 3,
        },
        {
          id: 3,
          name: 'phone_number',
          align: 'left',
          label: 'Phone Number',
          field: 'phone_number',
          value: 'phone_number',
          text: 'Phone Number',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: false,
          order_number: 2,
        },
        {
          id: 4,
          name: 'Has WA',
          align: 'center',
          label: 'has_wa',
          field: 'has_wa',
          value: 'has_wa',
          text: 'Has WA',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: false,
          order_number: 4,
        },

        {
          id: 5,
          name: 'Created At',
          align: 'center',
          label: 'created_at',
          field: 'created_at',
          value: 'created_at',
          text: 'Created At',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: false,
          order_number: 4,
        },
        {
          id: 6,
          name: 'Updated At',
          align: 'center',
          label: 'updated_at',
          field: 'updated_at',
          value: 'updated_at',
          text: 'Updated At',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: true,
          defaultValue: '',
          editable: false,
          order_number: 5,
        },
      ]
      await this.pouchSettings.remote.put({
        _id: id + 'columns',
        key: id + 'columns',
        value: column,
      })
    },
    async mixinGetContactGroup() {
      let filteredData = []
      const instances = await this.loadSettingsById('instances', true)
      const instancesNumber = instances.value.map(el => el.phone_number)
      const pouch = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)

      let repStatus = await this.checkReplicateContacts()
      let db = repStatus.diff < 1 ? pouch.db : pouch.remote

      let dataGroup = await db.allDocs({ include_docs: true })
      filteredData = dataGroup.rows
        .map(elm => elm.doc)
        .filter(elf => elf.phone_number === elf._id)
        .filter(
          el =>
            (el.phone_number_show === 'Group' || el.phone_number?.length > 16) &&
            el.archived !== 1 &&
            (el.ch !== undefined || el.data_channel !== undefined),
        )
        .filter(elc => instancesNumber.includes(elc.data_chanel) || instancesNumber.includes(Object.keys(elc.ch)[0]))
      return filteredData
    },
    async mixinGetContactById(idContact) {
      if (!this.pouchContacts) {
        this.pouchContacts = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      }
      let contact = this.pouchContacts.remote.get(idContact)
      if (contact) {
        return contact
      } else return {}
    },
    async checkGroupMember(jid, idContact) {
      let retData = []
      let channel = {}
      let activeInts = {}
      let contact = await this.mixinGetContactById(idContact)

      const instances = await this.loadSettingsById('instances', true)
      let tryCheck = true

      // check in all instances if not having CH object and data_chanel
      if (contact.data_channel === undefined && contact.ch === undefined) {
        for (let index = 0; index < instances.value.length; index++) {
          if (tryCheck) {
            const element = instances.value[index]
            const subidInstances = `${element.sub_id}-${element.instance_id}`
            const endpoint = `${process.env.VUE_APP_WA_API_URL.replace(/['"]+/g, '')}group/${subidInstances}/${jid}`
            // //console.log(endpoint, 'endpoint')
            try {
              const res = await this.$axiosLimit.get(endpoint)
              const response = JSON.parse(JSON.stringify(res.data))
              if (response.participants) {
                retData = await this.mixinMappingNameGroup(response.participants)
                tryCheck = false
                return retData
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      }

      if (contact.data_channel !== undefined) {
        channel = contact.data_channel
        //console.log(channel, 'channel if')
        activeInts = instances.value.filter(el => el.phone_number === channel)
      } else if (contact.ch !== undefined) {
        channel = Object.keys(contact.ch)[0]
        //console.log(channel, 'channel else')
        activeInts = instances.value.filter(el => el.phone_number === channel)
      } else {
        return []
      }
      // //console.log(activeInts, 'activeInts')
      const subidInstances = `${activeInts[0].sub_id}-${activeInts[0].instance_id}`
      const endpoint = `${process.env.VUE_APP_WA_API_URL.replace(/['"]+/g, '')}group/${subidInstances}/${jid}`
      try {
        const res = await this.$axiosLimit.get(endpoint)
        const response = JSON.parse(JSON.stringify(res.data))
        if (response.participants) {
          retData = await this.mixinMappingNameGroup(response.participants)
        }
      } catch (error) {
        console.log(error)
      }

      return retData
    },
    async mixinMappingNameGroup(data) {
      const pouch = await this.connectPouchDb(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, false)
      const limit = await pouch.remote.allDocs({ include_docs: false })
      const selector = {
        selector: {
          name: {
            $gte: null,
          },
        },
        fields: ['_id', 'name', 'phone_number'],
        limit: limit.total_rows,
      }
      let dataContact = await pouch.remote.find(selector)
      let retData = data.map(e => {
        let phone = e.id.split('@')[0]
        let idx = _findIndex(dataContact.docs, function (x) {
          return x._id === phone
        })
        if (idx > -1) {
          return { _uid: phone, name: dataContact.docs[idx].name, phone_number_show: phone, phone_number: phone }
        } else {
          return { _uid: phone, name: phone, phone_number_show: phone, phone_number: phone }
        }
      })
      return retData
    },
    async loadHistoryOperatorFirestoreStatistik(start = null, end = null) {
      if (!start) start = new Date(new Date().setDate(new Date().getDate() - 14))
      if (!end) end = new Date()

      try {
        const historyRef = collectionGroup(firestoreDb, 'messages')
        const queryData = query(
          historyRef,
          where('subId', '==', this.user.sub_id),
          where('history', '==', true),
          where('timestamp', '>=', Timestamp.fromDate(start)),
          where('timestamp', '<=', Timestamp.fromDate(end)),
          orderBy('timestamp', 'desc'),
        )
        const data = await getDocs(queryData)
        //console.log(data.empty, 'data.empty')
        if (data.empty) {
          return []
        }

        const historyListTable = []
        let timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
        const dataMessageChenk = []

        data.forEach(item => {
          let username = ''
          let avatar = ''
          let phone = ''

          dataMessageChenk.push(item.data())

          if (item.data().contact) {
            username = item.data().contact.name
            avatar = item.data().contact.avatar
            phone = item.data().contact.phone
          }

          let latestMessage = item.data().dataMessage.map(mess => {
            let objMess = { ...mess }

            objMess.timestamp = new Date(mess.timestamp.seconds * 1000 - timezoneOffset).toISOString().substring(11, 16)
            objMess.dateHistory = new Date(mess.timestamp.seconds * 1000 - timezoneOffset).toString()
            objMess._id = mess.source_id
            objMess.senderId = mess.sender_id
            objMess.system = false
            objMess.new = false
            objMess.username = mess.fromMe ? item.data().channel.name : username

            return objMess
          })

          const obj = {
            messageId: item.id,
            messagePath: item.ref.path,
            email: item.data().assign_to.email ? item.data().assign_to.email : '-',
            timestamp: item.data().lastTimestamp / 1000,
            chat: '',
            contact: username,
            currentUserId: item.data().channel.identifier,
            reply_time: item.data().totalReplyTime / item.data().totalCountReply,
            latest_message: latestMessage,
            room: null,
            channel: `${item.data().channel.name} - ${item.data().channel.identifier}`,
            duration: (item.data().lastTimestamp - item.data().firstTimestamp) / 1000,
            rating: item.data().rating ? item.data().rating : 0,
          }

          obj.room = {
            roomId: 1,
            roomName: username,
            avatar: avatar,
            unreadCount: 0,
            index: 1,
            lastMessage: {
              content: latestMessage[latestMessage.length - 1].content,
              senderId: latestMessage[latestMessage.length - 1].sender_id,
              username: latestMessage[latestMessage.length - 1].username,
              timestamp: latestMessage[latestMessage.length - 1].timestamp,
              saved: false,
              distributed: false,
              seen: false,
              new: false,
            },
            users: [phone],
            typingUsers: [],
          }

          historyListTable.push(obj)
        })
        this.allConversation = JSON.parse(JSON.stringify(dataMessageChenk))
        return historyListTable
      } catch (err) {
        console.error(err)

        return []
      }
    },
  },
}
