export function setShowRoomsList(state, showRoomsList) {
  state.showRoomsList = showRoomsList
}

export function setSidebarLeft(state, data) {
  state['sidebar-left'] = data
}

export function setCheckShowListContact(state, data) {
  state.checkShowListContact = data
}
export function setStyleHeight(state, data) {
  state['style-height'] = data
}
export function setStyleHeight2(state, data) {
  state['style-height-2'] = data
}

export function setBannerAlert(state, data) {
  state['banner-alert'] = data
}
export function setSidebarRight(state, data) {
  state['sidebar-right'] = data
}

export function setMessages(state, data) {
  state['messages-filter'] = data
}

export function setBannerAlertText(state, data) {
  state['banner-alert-text'] = data
}

export function setLabelFilter(state, data) {
  state.labelFilter = data
  // state.setLabelFilter = Vue.set({}, state.setLabelFilter, data)
}

export function setLabelValue(state, data) {
  state.labelValue = data
}

export function setContactRooms(state, data) {
  state.contactRooms = data
}

export function setEmptyLabel(state, data) {
  state.isLabelEmpty = data
}

export function setNotifications(state, data) {
  state.notifications = data
}

export function setCurrentOffset(state, data) {
  state.currentOffset = data
}

export function setFilterRoom(state, data) {
  state.filterRoom = data
}

export function setNewChat(state, data) {
  state.newChat = data
}

export function setNewChatContent(state, data) {
  state.newChatContent = data
}

export function setFilterChats(state, data) {
  state.filterChats = data
}

export function setShowCheckbox(state, data) {
  state.showCheckbox = data
}

export function setIsTagChanged(state, data) {
  state.isTagChanged = data
}

export function setLoadingDownloadFile(state, data) {
  state.loadingDownloadFile = data
}
