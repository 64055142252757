import {
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiBroadcast,
  mdiCardAccountMailOutline,
  mdiChartBar,
  mdiClipboardAccountOutline,
  mdiFolder,
  mdiForumOutline,
  mdiHomeOutline,
  mdiMonitorCellphone,
  mdiNewspaperVariant,
  mdiRobot,
} from '@mdi/js'

// import store from '../../store'

// const user = store.getters['auth/getUser']
// store.dispatch('subscriptions/getSubscriptionInfo', user.email)

// const subscriptionInfo = store.getters['subscriptions/getSubscriptionInfo']
// //console.log(
//   subscriptionInfo,
//   'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaapppppppppppppppppppppppppppppppppppppp',
// )

export default [
  {
    title: 'Channels',
    icon: mdiHomeOutline,
    to: 'channels',
  },
  {
    title: 'Chats',
    icon: mdiForumOutline,
    to: 'inbox',
  },
  {
    title: 'Broadcast',
    icon: mdiBroadcast,
    to: 'broadcast-list',
  },
  {
    title: 'Statistics',
    icon: mdiChartBar,
    children: [
      {
        title: 'Broadcast Statistics',
        to: 'broadcast-statistic',
      },
      {
        title: 'Chat Statistics',
        to: 'chat-statistic',
      },
      {
        title: 'Operator Statistics',
        to: 'operator-statistic',
      },
    ],
  },
  {
    icon: mdiCardAccountMailOutline,
    title: 'contacts.contactList',
    to: 'contacts',
  },
  {
    icon: mdiNewspaperVariant,
    title: 'Templates',
    to: 'templates',
  },
  {
    title: 'Team',
    icon: mdiAccountGroup,
    children: [
      {
        title: 'Team Members',
        icon: mdiClipboardAccountOutline,
        to: 'operators-list',
      },
      {
        icon: mdiAccountGroupOutline,
        title: 'Team Roles',
        to: 'team-roles',
      },
    ],
  },
  {
    icon: mdiFolder,
    title: 'My Assets',
    to: 'assets',
  },
  {
    title: 'Chatbot',
    icon: mdiRobot,
    to: 'chatbot',
  },

  // {
  //   title: 'campaignManager',
  //   icon: mdiBullhornOutline,
  //   to: 'campaign-manager',
  // },
  // {
  //   title: 'landingPage',
  //   icon: mdiNewspaper,
  //   to: 'landing-page',
  // },
  // {
  //   title: 'appsMarketplace',
  //   icon: mdiWidgets,
  //   to: 'apps-list',
  // },
  // {
  //   title: 'chatSetting',
  //   icon: mdiMessageCogOutline,
  //   children: [
  //     {
  //       title: 'Broadcast Timeline',
  //       icon: mdiCalendarMonth,
  //       to: 'broadcast-scheduled',
  //     },
  //     {
  //       title: 'Template',
  //       icon: mdiNoteTextOutline,
  //       to: 'message-template',
  //     },
  //     {
  //       title: 'Label',
  //       icon: mdiLabelMultiple,
  //       to: 'label-list',
  //     },
  //     {
  //       title: 'Chat Data',
  //       icon: mdiMessageSettingsOutline,
  //       to: 'contacts-list',
  //     },
  //   ],
  // },
  // {
  //   title: 'Team',
  //   icon: mdiAccountGroup,
  //   children: [
  //     {
  //       title: 'Team Members',
  //       icon: mdiClipboardAccountOutline,
  //       to: 'operators-list',
  //     },
  //     {
  //       title: 'Replies History',
  //       icon: mdiClipboardAccountOutline,
  //       to: 'replies-history',
  //     },
  //   ],
  // },

  // {
  //   title: 'Finance',
  //   icon: mdiCashMultiple,
  //   children: [
  //     {
  //       icon: mdiCashFast,
  //       title: 'Manage Salary',
  //       to: 'operators-list',
  //     },
  //     {
  //       icon: mdiAccountCashOutline,
  //       title: 'Manage Loan',
  //       to: 'replies-history',
  //     },
  //     {
  //       icon: mdiScriptTextOutline,
  //       title: 'Invoice',
  //       to: 'replies-history',
  //     },
  //   ],
  // },
  // {
  //   title: 'statistic',
  //   icon: mdiChartBar,

  //   // to: 'apps-list',
  //   children: [
  //     {
  //       icon: mdiChartBoxOutline,
  //       title: 'Broadcast Statistic',
  //       to: 'broadcast-statistic',
  //     },
  //     {
  //       icon: mdiCardAccountDetailsStarOutline,
  //       title: 'User Performance',
  //       to: 'user-performance',
  //     },
  //     {
  //       icon: mdiChartTimeline,
  //       title: 'Chat Statistic',
  //       to: 'chat-statistic',
  //     },

  //     // {
  //     //   icon: mdiFileChart,
  //     //   title: 'Replies Statistic',
  //     //   to: 'operator-statistic',
  //     // },

  //     // {
  //     //   title: 'Link Rotator',
  //     //   icon: mdiLink,
  //     //   to: 'link-generator',
  //     // },
  //     // {
  //     //   title: 'Link Tracker',
  //     //   icon: mdiLinkVariantPlus,
  //     //   to: 'link-tracker',
  //     // },
  //     // {-
  //     //   title: 'Live Chat Widget',
  //     //   icon: mdiChat,
  //     //   to: 'live-chat-widget',
  //     // },
  //     // {
  //     //   title: 'My Apps',
  //     //   icon: mdiWidgets,
  //     //   to: 'apps-list',
  //     // },
  //     // {
  //     //   title: 'eShop',
  //     //   icon: mdiShopping,
  //     //   to: 'e-ordering',
  //     // },
  //     // {
  //     //   title: 'eForm',
  //     //   icon: mdiFormSelect,
  //     //   to: 'e-form',
  //     // },
  //     // {
  //     //   title: 'Chatbot',
  //     //   icon: mdiRobotHappyOutline,
  //     //   to: 'chatbot',
  //     // },
  //   ],
  // },
  // {
  //   title: 'installedApps',
  //   icon: mdiWidgets,
  //   children: [],
  // },
  // {
  //   title: 'finance',
  //   icon: mdiCashMultiple,
  //   children: [
  //     {
  //       title: 'Manage Project',
  //       icon: mdiDomain,
  //       to: 'manage-project',
  //     },
  //     {
  //       title: 'Manage Salary',
  //       icon: mdiCashFast,
  //       to: 'manage-salary',
  //     },
  //     {
  //       title: 'Manage Loan',
  //       icon: mdiAccountCashOutline,
  //       to: 'manage-loan',
  //     },

  //     // {
  //     //   title: 'Invoice',
  //     //   icon: mdiScriptTextOutline,
  //     //   to: 'manage-invoice',
  //     // },
  //   ],
  // },
  {
    title: 'Settings',
    icon: mdiMonitorCellphone,
    to: 'billings',
  },

  // {
  //   title: 'Marketing Calendar',
  //   icon: mdiCalendarMonth,
  //   to: 'marketing-calendar',
  // },

  // {
  //   title: 'Link Generator',
  //   icon: mdiLink,
  //   to: 'link-generator',
  // },
  // {
  //   title: 'Live Chat Widget',
  //   icon: mdiChat,
  //   to: 'live-chat-widget',
  // },

  // {
  //   title: 'Test',
  //   icon: mdiAccountGroup,
  //   children: [
  //     {
  //       icon: mdiClipboardAccountOutline,
  //       title: 'b',
  //       to: 'test-b',
  //     },
  //     {
  //       icon: mdiClipboardAccountOutline,
  //       title: 'a',
  //       to: 'test-a',
  //     },
  //   ],
  // },
]
